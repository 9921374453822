import React from "react";
import { useNavigate  } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';

const SignUp = () => {
  const navigate = useNavigate();

  function handleRightClick() {
    navigate("restaurantsignup");
  }
  function handleLeftClick() {
    navigate("customersignup");
  }

  return (
    <Container fluid>
      <Row className="signincontainer">

            <Col xs={12} sm={6} md={6} lg={6} className="signupleftcolnew">
            <div className="signuprightsidenew">
              <h1 className="signupleftsideheading">Are you a Customer?</h1>
              <p  className="signupleftsidepara" >Join now to explore food options near you!</p>

             <Button id="signupleftbutton" className="rippletwo" onClick={handleLeftClick}>
              Customer
              </Button>
              
              </div>
           
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className="signuprightcolnew">
              <div className="signuprightsidenew">
              <h1 className="signuprightsideheading">Are you a Restaurant?</h1>
              <p  className="signuprightsidepara" >Join now to avail our services for free!</p>
            <Button id="signuprightbutton" className="ripple" onClick={handleRightClick}>
              Restaurant
              </Button>
              </div>
            </Col>
            </Row>
      

    </Container>
  );
};

export default SignUp;