// src/components/RestMenuCard.js
import React from "react";
// import Moment from "react-moment";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import Card from "react-bootstrap/Card";
import Col from 'react-bootstrap/Col';

export default function RestMenuCard({ restaurant, id, onDelete, onEdit }) {
  return (
    <Card className="restaurant-card">
      {/* <Moment className="restaurant-time" fromNow>
        {restaurant.timestamp?.toDate()}
      </Moment> */}
      <Card.Img
        variant="top"
        loading="lazy" // Check if it works
        className="restaurant-card-img"
        src={restaurant.profileImageUrl}
        alt="Cover"
      />
      <div className="card-text-overlay">
        <Card.Body>
          <Link to={`/r/${restaurant.urlName}`} className="custom-link" target="_blank">
            <Card.Title>{restaurant.restName}</Card.Title>
          </Link>
          {onDelete && (
            <FaTrash className="restaurant-delete" onClick={() => onDelete(restaurant.id)} />
          )}
          {onEdit && (
            <MdEdit className="restaurant-edit" onClick={() => onEdit(restaurant.id)} />
          )}
        </Card.Body>
      </div>
    </Card>
  );
}
