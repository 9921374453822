import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from "../Spinner";
import { Scanner } from '@yudiel/react-qr-scanner';
import RestMenuCard from "./RestMenuCard";
import Button from 'react-bootstrap/Button';

const MenusPage = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showQRScanner, setShowQRScanner] = useState(false);
  const [scannedData, setScannedData] = useState(null); // Store scanned QR code data
  const [cameraError, setCameraError] = useState(null); // State to store camera error

  const customStyles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "300px",
      width: "300px",
      marginTop: "20px",
      backgroundColor: "#9DC08B", // Light background for visibility
      border: "2px solid #40513B", // Border for the container
      borderRadius: "10px", // Rounded corners
      padding: "20px",
      boxShadow: "0 4px 12px rgba(28, 126, 54, 0.15)", // Soft shadow for depth
    },
    video: {
      width: "100%",
      height: "100%",
      borderRadius: "10px", // To match the container
    },
  };

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "usersrestaurants"));
        const restaurantsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRestaurants(restaurantsList);
      } catch (error) {
        console.error("Error fetching restaurants: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurants();
  }, []);

  const handleToggleQRScanner = async () => {
    try {
      if (showQRScanner) {
        setShowQRScanner(false);
      } else {
        // Check camera access before showing the scanner
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (stream) {
          setShowQRScanner(true);
          setCameraError(null);
          // Stop the stream after checking access
          stream.getTracks().forEach(track => track.stop());
        }
      }
    } catch (error) {
      console.error("Camera access error:", error);
      alert("An error occurred while accessing the camera. Please check your browser settings.");
      setCameraError("Unable to access the camera. Please check your browser settings.");
    }
  };
  
  const handleScan = (data) => {
    if (data) {
      // console.log("Scanned Data: ", data);
      // console.log("Scanned Data: ", data[0].rawValue);
      setScannedData(data[0].rawValue);
      setShowQRScanner(false);

    }
  };

  const handleError = (error) => {
    console.error("QR Scanner Error: ", error);
    if (error.name === "NotAllowedError") {
      setCameraError("Camera access is denied. Please enable camera permissions in your browser.");
    } else {
      setCameraError("An error occurred while accessing the camera.");
    }
    setShowQRScanner(false); // Close scanner if error occurs
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <Row  className='menuspagecontainer'>
    

<Row className="restaurants-container">
<h1 className="menuspageheading">
  Explore menus of restaurants. If you are at a restaurant and need to scan a QR code, open your mobile's camera or click the button below.
</h1>
<div className="menuspagebuttoncontainer">

<Button id="menuspagebutton" className="ripple" onClick={handleToggleQRScanner}>
            {showQRScanner ? "Close QR Scanner" : "Open QR Scanner"}
          </Button>
</div>

          {showQRScanner && (
            <Row className="qrscannercontainer">

            <Scanner
              onScan={handleScan} 
              onError={handleError} 
              videoConstraints={{ facingMode: 'environment' }} // Use back camera
              styles={customStyles} 
            />
            </Row>
           )}
     
      {scannedData && (
            <p>Scanned QR Code Data: <Link to={scannedData} >{scannedData} </Link></p>
      )}

{restaurants.map((restaurant) => (
          <Col key={restaurant.id} md={4} className="mb-4">
            <RestMenuCard restaurant={restaurant} id={restaurant.id} />
          </Col>
        ))}
</Row>    
      
  
        
      </Row>  
    </Container>
  );
};

export default MenusPage;