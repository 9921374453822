import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { Row, Col, Container, Button } from "react-bootstrap";
import logoImage from "../../assets/images/logo.png";
import { toast } from "react-toastify";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase"; // Make sure to import your Firestore instance

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;

  function onChange(e) {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if the user is a restaurant
      const restaurantDocRef = doc(db, "usersrestaurants", user.uid);
      const restaurantDocSnapshot = await getDoc(restaurantDocRef);

      if (restaurantDocSnapshot.exists()) {
        // Restaurant found, navigate to home
        navigate("/");
      } else {
        // If not a restaurant, check if it's a customer
        const customerDocRef = doc(db, "userscustomers", user.uid);
        const customerDocSnapshot = await getDoc(customerDocRef);

        if (customerDocSnapshot.exists()) {
          // Customer found, navigate to home
          navigate("/");
        } else {
          // If neither, sign out and show an error
          await auth.signOut();
          toast.error("Sorry, no data found for this user. Please sign up.");
        }
      }
    } catch (error) {
      toast.error("Bad user credentials");
    }
  }

  return (
    <Container fluid>
    <Row className="signincontainer">
    <Col xs={12} sm={6} md={6} lg={6} className="loginleftcolnew">
    <div className="loginleftcolcontainer">

    <div className="logoimgcontainer">
    <img src={logoImage} alt="logo" className="registerlogoimg" />

    </div>
    </div>
           
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className="loginrightcolnew">
            <div className="loginrightsidenew">
        <div className="leftdivcard">
        <h1 className="loginpageheading">Enter the Food Universe!</h1>
          <form onSubmit={onSubmit}>
            <div className="text-section">
              <input
                className="emailnew"
                type="email"
                id="email"
                placeholder="Email Address"
                required={true}
                value={email}
                onChange={onChange}
              />
            </div>
            <div className="pw-section">
              <input
                className="emailnew"
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                required={true}
                value={password}
                onChange={onChange}
              />
              {showPassword ? (
                <AiFillEyeInvisible
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="eye-iconnew"
                />
              ) : (
                <AiFillEye
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="eye-iconnew"
                />
              )}
            </div>
            <div className="logintbuttoncontainer">
            <Button id="logintbutton" className="rippletwo" type="submit">
              LOGIN
            </Button>
            </div>
          
          
          </form>
          
        </div>
        <div className="loginbottomsection">
              <p className="text-section">
                Don't have an account?
                <Link to="/signup" className="register-button">
                  Register
                </Link>
              </p>
              <p className="text-section">
                <Link to="/forgot-password" className="forgotpw-button">
                  Forgot Password?
                </Link>
              </p>
            </div>
            </div>
            </Col>

     
   
     
    </Row>   
    
        </Container>
  );
};

export default Login;