import React, { useEffect, useState, useRef } from "react";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";
import Spinner from "../Spinner";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaStar, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './RGeneralMenuPage.css';

const RGeneralMenuPage = () => {

    const params = useParams();
    const restaurantName = params.restaurantName;
    const [loading, setLoading] = useState(true);
    const [menuData, setMenuData] = useState([]);
    const [expandedSections, setExpandedSections] = useState({});
    const sectionRefs = useRef([]); // Create refs for each section


  useEffect(() => {
    const fetchMenuData = async () => {
  
        try {
            // Reference to the restaurant's general menu document
            const generalMenuDocRef = doc(db, "restaurants", "doccontainingallRestaurants", restaurantName, "generalMenu");
            const generalMenuDocSnapshot = await getDoc(generalMenuDocRef);
    
            if (generalMenuDocSnapshot.exists()) {
              const sections = generalMenuDocSnapshot.data().sections || [];

               // Sort sections by sectionNumber
              sections.sort((a, b) => a.sectionNumber - b.sectionNumber);


              const sectionsData = [];
    
              for (const section of sections) {
                const sectionName = section.name; // Ensure sectionName is a string
                const sectionOriginalName = section.originalName;
                const topNote = section.topNote;
                const bottomNote = section.bottomNote;
                const sectionItemsSnapshot = await getDocs(collection(generalMenuDocRef, sectionName));
                const items = sectionItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
                sectionsData.push({ sectionName, sectionOriginalName, topNote, bottomNote, items });
              }
    
              setMenuData(sectionsData);
            }
          } catch (error) {
            console.error("Error fetching menu data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchMenuData();
      }, [restaurantName]);

      const toggleSection = (sectionName) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionName]: !prev[sectionName]
        }));
    };
      // Scroll to the section without modifying the URL
      const scrollToSection = (index) => {
        sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    };

      if (loading) {
        return <Spinner />;
      }
  return (
    <Container fluid className="mainpage">

            <Row>
                <Col md={3} className="menu-sidebar">
                    <h3 className="menuheading">Menu</h3>
                    <ul>
                        {menuData.map((section, index) => (
                            <li key={index}>
                                <span onClick={() => scrollToSection(index)}>{section.sectionOriginalName}</span> {/* No href */}
                            </li>
                        ))}
                    </ul>
                </Col>
                <Col md={9}>

                {menuData.length > 0 ? (
        menuData.map((section, sectionIndex) => (
          <div key={sectionIndex} id={`section-${sectionIndex}`} className="menu-section" ref={(el) => (sectionRefs.current[sectionIndex] = el)}>
          {/* Assign refs */}
          <h2 onClick={() => toggleSection(section.sectionName)} className="section-header">
                                {section.sectionOriginalName}
                                {expandedSections[section.sectionName] ? <FaChevronUp /> : <FaChevronDown />}
                            </h2>


                            <div className={`section-content ${expandedSections[section.sectionName] ? 'expanded' : ''}`}>
                                <Row>
                            {section.topNote && <p className="section-note">{section.topNote}</p>}
                                    {section.items.map((item, itemIndex) => (
                                        <Col md={6} key={itemIndex}>
                                            <Card className="menu-item">
                                                <Card.Body>
                                                    <Card.Title className="item-name-link">
                                                        <Link to={`/r/${restaurantName}/menu/${section.sectionName}/${item.id}`} >{item.name}</Link>
                                                    </Card.Title>
                                                    <Card.Text>{item.nameExtra}</Card.Text>
                                                    <Card.Text>{item.description}</Card.Text>
                                                    <Card.Text>{item.descriptionExtra}</Card.Text>
                                                    <Card.Text>{item.tasteCategory}</Card.Text>
                                                    <Card.Text>{item.itemPriceRange}</Card.Text>
                                                    <Card.Text>{item.itemType}</Card.Text>
                                                    <Card.Text>{item.foodAllergies}</Card.Text>
                                                    <div className="item-details">
                                                        <span className="price">${item.price}</span>
                                                        <span className="rating"><FaStar /> {item.itemRating} ({item.itemRatingBy})</span>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                    {section.bottomNote && (
                <p className="section-note">{section.bottomNote}</p>)}
                                </Row>
                            </div>    
          </div>
        ))
      ) : (
        <Row>
          <Col>
            <p>No menu data available.</p>
          </Col>
        </Row>
      )}
                </Col>              
                </Row>
   
      
    
    </Container>
  );
};

export default RGeneralMenuPage;
